
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { User } from '@/models/user';
import AppSwitcher from '@/components/layout/AppSwitcher.vue';

@Component({
    name: 'Sidebar',
    components: {
        AppSwitcher,
    },
})
export default class Sidebar extends Vue {
    @Getter('account/loggedIn') private loggedIn: boolean;
    @Getter('account/haveAccess') private haveAccess: boolean;
    @Getter('account/isEmailConfirmed') private isEmailConfirmed: boolean;
    @Getter('account/currentUser') private currentUser: User;

    @Prop() private isSidebarReduced: boolean;

    private goToHomePage() {
        if (this.loggedIn) {
            this.$router.push('/');
        } else {
            this.$router.push('/auth/login');
        }
    }

    private toggle() {
        this.$emit('toggleSidebar');
    }
}
