
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import { User } from '../../models/user';
import { userWebservice } from '../../webservices/user';

@Component({
    name: 'AccountSettings',
    components: {},
})
export default class AccountSettings extends Vue {
    private changeEmailErrorMsg: string = '';
    private changePasswordErrorMsg: string = '';
    private changeFirstnameErrorMsg: string = '';
    private changeLastnameErrorMsg: string = '';

    private isEmailOpen: boolean = false;
    private isPasswordOpen: boolean = false;
    private isNameOpen: boolean = false;

    private updatingEmail: boolean = false;
    private updatingName: boolean = false;
    private updatingPasswprd: boolean = false;

    private user = {
        id: 0,
        firstname: '',
        lastname: '',
        email: '',
        new_email: '',
        email_confirmation: '',
        password: '',
        password_confirmation: '',
        new_password: '',
        email_confirmed: false,
    } as User;

    @Getter('account/currentUser') private currentUser: User;

    // Mounted is called initially
    private mounted() {
        // deep copy the current user object
        this.user = JSON.parse(JSON.stringify(this.currentUser));
    }

    private changeName() {
        const self = this;
        if (this.user.firstname === '') {
            this.changeFirstnameErrorMsg = 'Bitte einen Vornamen eingeben';
            return false;
        } else if (this.user.lastname === '') {
            this.changeLastnameErrorMsg = 'Bitte einen Nachnamen eingeben';
            return false;
        } else if (this.user.firstname.length < 2) {
            this.changeFirstnameErrorMsg = 'Der Name muss mindestens drei Zeichen lang sein';
            return false;
        } else if (this.user.lastname.length < 2) {
            this.changeLastnameErrorMsg = 'Der Name muss mindestens drei Zeichen lang sein';
            return false;
        }

        this.updatingName = true;

        userWebservice.changeName(this.user.firstname, this.user.lastname).then((response) => {
            self.updatingName = false;
            self.updateUserInfo();
        });
    }

    private changePassword() {
        if (this.user.password === '' || this.user.new_password === '' || this.user.password_confirmation === '') {
            this.changePasswordErrorMsg = 'Bitte alle Felder ausfüllen';
            return false;
        } else if (this.user.new_password.length < 6) {
            this.changePasswordErrorMsg = 'Das Passwort muss mindestens 6 Zeichen lang sein';
            return false;
        } else if (this.user.new_password !== this.user.password_confirmation) {
            this.changePasswordErrorMsg = 'Die Passwörter sind nicht identisch';
            return false;
        }

        const self = this;
        this.updatingPasswprd = true;

        userWebservice
            .changePassword(this.user.password, this.user.password_confirmation)
            .then((response) => {
                self.updatingPasswprd = false;
                self.user.password = '';
                self.user.new_password = '';
                self.user.password_confirmation = '';

                this.updateUserInfo();
            })
            .catch((error) => {
                self.updatingPasswprd = false;
                self.changePasswordErrorMsg = error.response.data.message;
            });
    }

    private changeEmail() {
        if (this.user.new_email === '' || this.user.email_confirmation === '') {
            this.changeEmailErrorMsg = 'Bitte alle Felder ausfüllen';
            return false;
        } else if (!this.validateEmail(this.user.new_email)) {
            this.changeEmailErrorMsg = 'Die eingegebene E-Mail Adresse ist ungültig';
            return false;
        } else if (this.user.new_email !== this.user.email_confirmation) {
            this.changeEmailErrorMsg = 'Die E-Mail Adresse sind nicht identisch';
            return false;
        }

        const self = this;
        this.updatingEmail = true;

        userWebservice
            .changeEmail(this.user.new_email)
            .then((response) => {
                this.updatingEmail = false;
                this.user.new_email = '';
                this.user.email_confirmation = '';
                this.updateUserInfo();
            })
            .catch((error) => {
                this.updatingEmail = false;
                if (error.response.data.message === 'emailTaken') {
                    this.changeEmailErrorMsg = this.$t('errors.validation.emailTaken').toString();
                }
            });
    }

    private validateEmail(email: string) {
        /* tslint:disable-next-line:max-line-length */
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email.toLowerCase());
    }

    private updateUserInfo() {
        const self = this;
        userWebservice.getUserInfo().then((response) => {
            self.$store.commit('account/setCurrentUser', response.data);
        });

        // Show a snackbar notification
        this.$buefy.snackbar.open({
            duration: 3000,
            message: `Alle Änderungen wurden erfolgreich gespeichert`,
            type: 'is-primary',
            queue: false,
            position: 'is-top',
        });

        // After successfully changing the account, reset the error message
        this.changeEmailErrorMsg = '';
        this.changePasswordErrorMsg = '';
        this.changeFirstnameErrorMsg = '';
        this.changeLastnameErrorMsg = '';
    }
}
