
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { schoollevelWebservice } from '@/webservices/schoollevel';
import { Schoollevel } from '@/models/schoollevel';
import ConfirmModal from '@/components/prine/ConfirmModal.vue';
import * as R from 'ramda';

@Component({
    name: 'SchoollevelsList',
    components: {
        ConfirmModal,
    },
})
export default class SchoollevelsList extends Vue {
    private schoollevels: Schoollevel[] = [];
    private selectedSchoollevel: Schoollevel = { id: 0, name: '', title: '' } as Schoollevel;
    private newSchoollevel: Schoollevel = { id: 0, name: '', title: '' } as Schoollevel;
    private isLoading: boolean = true;
    private displayStudents: boolean = false;
    private perPage: number = 20;
    private isSearching: boolean = false;
    private searchQuery: string = '';
    private isCreateModalActive: boolean = false;
    private isEditModalActive: boolean = false;
    private isConfirmModalActive: boolean = false;

    private mounted() {
        console.log('mounted SchoollevelsList');
        this.getAll();
    }

    private toggleCreateModal() {
        this.isCreateModalActive = !this.isCreateModalActive;
        this.newSchoollevel = {
            id: 0,
            name: '',
            title: '',
            description: '',
            start_age: 7,
            end_age: 99,
        };
    }

    private toggleEditModal(
        schoollevel: Schoollevel = { id: 0, name: '', title: '', description: '', start_age: 12, end_age: 18 }
    ) {
        this.isEditModalActive = !this.isEditModalActive;
        this.selectedSchoollevel = JSON.parse(JSON.stringify(schoollevel));
    }

    private toggleConfirmModal(answer: boolean = false) {
        this.isConfirmModalActive = !this.isConfirmModalActive;
        if (answer && !R.isNil(this.selectedSchoollevel)) {
            this.remove(this.selectedSchoollevel);
        }
    }

    private async getAll() {
        this.schoollevels = await schoollevelWebservice.getAll();
        this.isLoading = false;
    }

    private async store() {
        if (
            !R.isNil(this.newSchoollevel) &&
            !R.isEmpty(this.newSchoollevel.title) &&
            !R.isEmpty(this.newSchoollevel.name) &&
            !R.isEmpty(this.newSchoollevel.start_age) &&
            !R.isEmpty(this.newSchoollevel.end_age)
        ) {
            this.isLoading = true;
            await schoollevelWebservice.store(this.newSchoollevel);
            this.getAll();
            this.isLoading = false;
            this.$buefy.snackbar.open({
                duration: 3000,
                message: 'Stored ' + this.newSchoollevel.title,
                type: 'is-success',
                position: 'is-bottom-right',
                queue: false,
            });

            this.newSchoollevel = { id: 0, name: '', title: '' } as Schoollevel;
            this.toggleCreateModal();
        }
    }

    private async update() {
        if (
            !R.isNil(this.selectedSchoollevel) &&
            !R.isEmpty(this.selectedSchoollevel.title) &&
            !R.isEmpty(this.selectedSchoollevel.name) &&
            !R.isEmpty(this.selectedSchoollevel.start_age) &&
            !R.isEmpty(this.selectedSchoollevel.end_age)
        ) {
            this.isLoading = true;
            await schoollevelWebservice.update(this.selectedSchoollevel);
            this.getAll();
            this.isLoading = false;
            this.$buefy.snackbar.open({
                duration: 3000,
                message: 'Updated ' + this.selectedSchoollevel.title,
                type: 'is-success',
                position: 'is-bottom-right',
                queue: false,
            });
            this.toggleEditModal();
        }
    }

    private async remove(schoollevel: Schoollevel) {
        this.toggleEditModal();

        this.isLoading = true;
        await schoollevelWebservice.remove(schoollevel);
        this.getAll();
        this.isLoading = false;

        this.$buefy.snackbar.open({
            duration: 3000,
            message: 'Removed ' + schoollevel.title,
            type: 'is-danger',
            position: 'is-bottom-right',
            queue: false,
        });
    }
}
