
import { Component, Prop, Vue } from 'vue-property-decorator';
import { CrystalPackageOrder } from '@/models/crystalPackageOrder.ts';
import { orderWebservice } from '@/webservices/order';
import moment from 'moment';

@Component({
    name: 'ManageCrystalOrders',
    components: {},
})
export default class ManageCrystalOrders extends Vue {
    private isLoading: boolean = true;
    private crystalPackageOrders: CrystalPackageOrder[] = [];
    private displayAll: boolean = false;

    private mounted() {
        this.getCrystalPackageOrders();
    }

    private async getCrystalPackageOrders() {
        this.isLoading = true;

        const response = await orderWebservice.getCrystalPackageOrders();

        if (!response) {
            this.$buefy.toast.open({
                message: 'Http 500: Es ist ein Fehler aufgetreten. Versuche es bitte später wieder.',
                type: 'is-danger',
                duration: 3000,
            });
        } else {
            this.crystalPackageOrders = response;
            this.sortCrystalOrders();
        }
        this.isLoading = false;
    }

    private filteredOrders(): CrystalPackageOrder[] {
        return this.displayAll
            ? this.crystalPackageOrders
            : this.crystalPackageOrders.filter((order) => order.status === 'paid');
    }

    private statusTagType(status: string) {
        if (status === 'cancelled' || status === 'declined' || status === 'error') {
            return 'is-danger';
        }
        return 'is-success';
    }

    private get sumCrystals() {
        return this.crystalPackageOrders.reduce((prev, cur) => {
            return this.displayAll
                ? prev + cur.package.crystals
                : cur.status === 'paid'
                ? prev + cur.package.crystals
                : prev;
        }, 0);
    }

    private get sumAmount() {
        return this.crystalPackageOrders.reduce((prev, cur) => {
            return this.displayAll ? prev + cur.amount : cur.status === 'paid' ? prev + cur.amount : prev;
        }, 0);
    }

    private sortCrystalOrders() {
        this.crystalPackageOrders.sort(function(a: CrystalPackageOrder, b: CrystalPackageOrder) {
            return new Date(b.ordered_date).getTime() - new Date(a.ordered_date).getTime();
        });
    }
}
