import axios from 'axios';
import HttpException from './HttpException';
import { PiquestSubject } from '@/models/piquestSubject';

class PiquestSubjectWebservice {
    public async getAll() {
        try {
            const response = await axios.get<PiquestSubject[]>('/piquest-subjects');
            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'There was an error fetching the piquest-subjects');
        }
    }

    public async store(piquestSubject: PiquestSubject) {
        try {
            const response = await axios.post('/piquest-subjects/store', {
                name: piquestSubject.name,
                title: piquestSubject.title,
            });
            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'There was an error storing the piquest-subject');
        }
    }

    public async update(piquestSubject: PiquestSubject) {
        try {
            const response = await axios.post('/piquest-subjects/update', {
                id: piquestSubject.id,
                name: piquestSubject.name,
                title: piquestSubject.title,
            });
            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'There was an error updating the piquest-subject');
        }
    }

    public async remove(piquestSubject: PiquestSubject) {
        try {
            const response = await axios.post('/piquest-subjects/remove', {
                id: piquestSubject.id,
            });
            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'There was an error removing the piquest-subject');
        }
    }
}
export const piquestSubjectWebservice = new PiquestSubjectWebservice();
