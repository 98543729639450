
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

@Component({
    name: 'Footer',
})
export default class Footer extends Vue {
    //     @Getter('acount/currentUser') private currentUser: User;
    //     @Prop() private Name: string;
    private mounted() {
        console.log('mounted Footer');
    }
}
