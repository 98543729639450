
import axios from 'axios';
import router from 'vue-router';
import { User } from '../../models/user';

import { Component, Prop, Vue } from 'vue-property-decorator';

import { userWebservice } from '../../webservices/user';

@Component({
    name: 'Register',
})
export default class Login extends Vue {
    private user = {
        id: 0,
        role_id: 4,
        firstname: '',
        lastname: '',
        email: '',
        new_email: '',
        email_confirmation: '',
        password: '',
        password_confirmation: '',
        new_password: '',
        email_confirmed: false,
        goal: 0,
        activated: false,
    } as User;

    private disabled: boolean = false;
    private errorMsg: string = '';
    private isLoading: boolean = false;

    private register() {
        if (this.validate()) {
            this.isLoading = true;
            userWebservice
                .register(this.user)
                .then((response) => {
                    this.login();
                })
                .catch((error) => {
                    // Check if the email is already taken
                    if (
                        error.response &&
                        error.response.data &&
                        error.response.data.errors &&
                        error.response.data.errors.hasOwnProperty('email')
                    ) {
                        this.errorMsg = 'emailTaken';
                    }
                    this.isLoading = false;
                });
        }
    }

    private login() {
        userWebservice
            .login(this.user.email, this.user.password)
            .then((response) => {
                this.errorMsg = '';
                this.$store.commit('account/setApiToken', response.data.api_token);

                userWebservice.getUserInfo().then((getUserInfoResponse) => {
                    this.$store.commit('account/setCurrentUser', getUserInfoResponse.data);
                    this.$router.push({ path: '/' });
                });
                this.isLoading = false;
            })
            .catch((error) => {
                console.log(error);
                this.isLoading = false;
            });
    }

    private validateEmail(email: string) {
        /* tslint:disable-next-line:max-line-length */
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email.toLowerCase());
    }

    private validate() {
        if (this.user.firstname === '') {
            this.errorMsg = 'firstname';
            return 0;
        } else if (this.user.lastname === '') {
            this.errorMsg = 'lastname';
            return 0;
        } else if (!this.validateEmail(this.user.email)) {
            this.errorMsg = 'email';
            return 0;
        } else if (this.user.password === '') {
            this.errorMsg = 'password';
            return 0;
        } else if (this.user.password_confirmation === '') {
            this.errorMsg = 'password_confirmation';
            return 0;
        } else {
            return true;
        }
    }
}
