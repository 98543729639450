
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { schooltypeWebservice } from '@/webservices/schooltype';
import { Schooltype } from '@/models/schooltype';
import ConfirmModal from '@/components/prine/ConfirmModal.vue';
import * as R from 'ramda';

@Component({
    name: 'SchooltypesList',
    components: {
        ConfirmModal,
    },
})
export default class SchooltypesList extends Vue {
    private schooltypes: Schooltype[] = [];
    private selectedSchooltype: Schooltype = { id: 0, name: '', title: '' } as Schooltype;
    private newSchooltype: Schooltype = { id: 0, name: '', title: '' } as Schooltype;
    private isLoading: boolean = true;
    private displayStudents: boolean = false;
    private perPage: number = 20;
    private isSearching: boolean = false;
    private searchQuery: string = '';
    private isCreateModalActive: boolean = false;
    private isEditModalActive: boolean = false;
    private isConfirmModalActive: boolean = false;

    private mounted() {
        console.log('mounted SchooltypesList');
        this.getAll();
    }

    private toggleCreateModal() {
        this.isCreateModalActive = !this.isCreateModalActive;
        this.newSchooltype = {
            id: 0,
            name: '',
            title: '',
        };
    }

    private toggleEditModal(schooltype: Schooltype = { id: 0, name: '', title: '' }) {
        this.isEditModalActive = !this.isEditModalActive;
        this.selectedSchooltype = JSON.parse(JSON.stringify(schooltype));
    }

    private toggleConfirmModal(answer: boolean = false) {
        this.isConfirmModalActive = !this.isConfirmModalActive;
        if (answer && !R.isNil(this.selectedSchooltype)) {
            this.remove(this.selectedSchooltype);
        }
    }

    private async getAll() {
        this.schooltypes = await schooltypeWebservice.getAll();
        this.isLoading = false;
    }

    private async store() {
        if (
            !R.isNil(this.newSchooltype) &&
            !R.isEmpty(this.newSchooltype.title) &&
            !R.isEmpty(this.newSchooltype.name)
        ) {
            this.isLoading = true;
            await schooltypeWebservice.store(this.newSchooltype);
            this.getAll();
            this.isLoading = false;
            this.$buefy.snackbar.open({
                duration: 3000,
                message: 'Stored ' + this.newSchooltype.title,
                type: 'is-success',
                position: 'is-bottom-right',
                queue: false,
            });

            this.newSchooltype = { id: 0, name: '', title: '' } as Schooltype;
            this.toggleCreateModal();
        }
    }

    private async update() {
        if (
            !R.isNil(this.selectedSchooltype) &&
            !R.isEmpty(this.selectedSchooltype.title) &&
            !R.isEmpty(this.selectedSchooltype.name)
        ) {
            this.isLoading = true;
            await schooltypeWebservice.update(this.selectedSchooltype);
            this.getAll();
            this.isLoading = false;
            this.$buefy.snackbar.open({
                duration: 3000,
                message: 'Updated ' + this.selectedSchooltype.title,
                type: 'is-success',
                position: 'is-bottom-right',
                queue: false,
            });
            this.toggleEditModal();
        }
    }

    private async remove(schooltype: Schooltype) {
        this.toggleEditModal();

        this.isLoading = true;
        await schooltypeWebservice.remove(schooltype);
        this.getAll();
        this.isLoading = false;

        this.$buefy.snackbar.open({
            duration: 3000,
            message: 'Removed ' + schooltype.title,
            type: 'is-danger',
            position: 'is-bottom-right',
            queue: false,
        });
    }
}
