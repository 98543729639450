/* tslint:disable */

import Vue from 'vue';
import App from './App.vue';
import router from './routing/router';
import store from './vuex/store';
// import './helpers/registerServiceWorker'

// Import Sentry for error logging framework
import Raven from 'raven-js';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import Buefy from 'buefy';
import axios, { AxiosError, AxiosResponse } from 'axios';
import VTooltip from 'v-tooltip';

// https://github.com/Akryum/v-tooltip
// Easy tooltips, popovers and dropdown with Popper.js
Vue.use(VTooltip);

// Import MomentsJS
import moment from 'moment';

// Main Sass file
import './assets/sass/main.scss';
import './assets/sass/spacing.scss';
import './assets/sass/style.scss';

// Font awesome icons
// imported in public/index.html
// <link rel="stylesheet" href="assets/css/fontawesome-pro.css">

// Import vue-spinner clip-loader component
Vue.component('clip-loader', ClipLoader);

// -- BUEFY --
// Vue JS Components for Bulma
Vue.use(Buefy, {
    defaultIconPack: 'fas',
});

Vue.prototype.buefy = Buefy;

// -- Filters --
Vue.filter('truncate', (text: string, stop: number, clamp: string) => {
    return text.slice(0, stop) + (stop < text.length ? clamp || '...' : '');
});

Vue.filter('formatNumber', (text: string) => {
    return text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
});

// Change Date format
Vue.filter('formatDateTime', (value: string) => {
    if (value) {
        return moment(value).format('DD.MM.YYYY, HH:mm');
    }
});

Vue.filter('formatDate', (value: string) => {
    if (value) {
        return moment(value).format('DD.MM.YYYY');
    }
});

Vue.filter('fromAgo', (value: string) => {
    if (value) {
        return moment(String(value)).fromNow();
    }
});

// ===== LOCALIZATION =====
// All localization is included here
import i18n from './localization/localization';
Vue.config.productionTip = false;

// Fetch the token
// const token = localStorage.getItem('api_token');
import Cookies from 'js-cookie';
const token = Cookies.get('api_token');

// If the token exist, set it in the authorization
if (token) {
    // Axios configuration
    axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    axios.defaults.headers.common.Authorization = 'Bearer '.concat(token);
    store.commit('account/setApiToken', token);
} else {
    store.commit('account/logout');
    window.location.href = '/#/auth/logout';
}

// Define the base api url
axios.defaults.baseURL = process.env.VUE_APP_API_URL;

Vue.prototype.BACKEND_BASE_URL = process.env.VUE_APP_BACKEND_BASE_URL;

// CURRENT VERSION NUMBER (18004 -> 18 year, 004 day of the year)
Vue.prototype.$version = '2.0.0.22280';

// Sentry error logging framework
Raven.config('https://3144e7d896e04f4fafe04126d7127891@sentry.io/306963', {
    release: Vue.prototype.$version,
    shouldSendCallback: () => {
        // Only send sentry callback when sentry is enabled in the env file
        if (process.env.VUE_APP_SENTRY_ENABLED !== undefined) {
            return process.env.VUE_APP_SENTRY_ENABLED!.trim() === 'true';
        }

        return false;
    },
}).install(); // TODO: check if its still working

// Add a 401 response interceptor
axios.interceptors.response.use(
    (response: AxiosResponse) => {
        return response;
    },
    (error: AxiosError) => {
        // check if there is a 401 response and the user is not on auth pages
        if (error.response && error.response.status === 401 && !window.location.href.includes('auth')) {
            window.location.href = '/#/auth/logout';
            // check for laravel passport inavlid_credentials error
        } else if (
            error.response !== undefined &&
            error.response.data.error !== 'invalid_credentials' &&
            error.response.status === 401 &&
            !window.location.href.includes('auth')
        ) {
            window.location.href = '/#/auth/logout';
            // check if the server is not responding at all
        } else if (error.response === undefined) {
            console.log('Gets called when offline');
            // window.location.href = '/#/auth/logout';
        } else {
            return Promise.reject(error);
        }
    }
);

// ===== VUE =====
// Create Vue Object and add all necesarry parts
new Vue({
    i18n,
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');
