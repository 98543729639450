
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { tagWebservice } from '@/webservices/tag';
import { Tag } from '@/models/tag';
import ConfirmModal from '@/components/prine/ConfirmModal.vue';
import * as R from 'ramda';

@Component({
    name: 'TagsList',
    components: {
        ConfirmModal,
    },
})
export default class TagsList extends Vue {
    private tags: Tag[] = [];
    private selectedTag: Tag = { id: 0, name: '', title: '', color: '#fdc05d' } as Tag;
    private newTag: Tag = { id: 0, name: '', title: '', color: '#fdc05d' } as Tag;
    private isLoading: boolean = true;
    private displayStudents: boolean = false;
    private perPage: number = 20;
    private isSearching: boolean = false;
    private searchQuery: string = '';
    private isCreateModalActive: boolean = false;
    private isEditModalActive: boolean = false;
    private isConfirmModalActive: boolean = false;

    private mounted() {
        console.log('mounted TagsList');
        this.getAll();
    }

    private openCreateModal() {
        this.isCreateModalActive = true;
    }

    private closeCreateModal() {
        this.isCreateModalActive = false;
        this.newTag = { id: 0, name: '', title: '', color: '#fdc05d' } as Tag;
    }

    private openEditModal(tag: Tag) {
        this.isEditModalActive = true;
        this.selectedTag = JSON.parse(JSON.stringify(tag));
    }

    private closeEditModal() {
        this.isEditModalActive = false;
        this.selectedTag = { id: 0, name: '', title: '', color: '#fdc05d' } as Tag;
    }

    private toggleConfirmModal(answer: boolean = false) {
        this.isConfirmModalActive = !this.isConfirmModalActive;
        if (answer && !R.isNil(this.selectedTag)) {
            this.remove(this.selectedTag);
        }
    }

    private async getAll() {
        this.tags = await tagWebservice.getAll();
        this.isLoading = false;
    }

    private async store() {
        if (!R.isNil(this.newTag) && this.newTag.name !== '' && this.newTag.title !== '' && this.newTag.color !== '') {
            this.isLoading = true;
            await tagWebservice.store(this.newTag);
            this.getAll();
            this.isLoading = false;
            this.$buefy.snackbar.open({
                duration: 3000,
                message: 'Stored ' + this.newTag.title,
                type: 'is-success',
                position: 'is-bottom-right',
                queue: false,
            });
            this.closeCreateModal();
        }
    }

    private async update() {
        if (
            !R.isNil(this.selectedTag) &&
            this.selectedTag.name !== '' &&
            this.selectedTag.title !== '' &&
            this.selectedTag.color !== ''
        ) {
            this.isLoading = true;
            await tagWebservice.update(this.selectedTag);
            this.getAll();
            this.isLoading = false;
            this.$buefy.snackbar.open({
                duration: 3000,
                message: 'Updated ' + this.selectedTag.title,
                type: 'is-success',
                position: 'is-bottom-right',
                queue: false,
            });
            this.closeEditModal();
        }
    }

    private async remove(tag: Tag) {
        this.closeEditModal();

        this.isLoading = true;
        await tagWebservice.remove(tag);
        this.getAll();
        this.isLoading = false;

        this.$buefy.snackbar.open({
            duration: 3000,
            message: 'Removed ' + tag.title,
            type: 'is-danger',
            position: 'is-bottom-right',
            queue: false,
        });
    }
}
