
import { Component, Vue } from 'vue-property-decorator';
import { demoViewWebService } from '@/webservices/demoView';
import { DemoToken } from '@/models/demoToken';

@Component({
    name: 'DemoView',
})
export default class DemoView extends Vue {
    private demoLink: string = '';
    private teacherBaseUrl = process.env.VUE_APP_TEACHER_URL;
    private demoToken: DemoToken = {} as DemoToken;

    mounted() {
        this.getDemoTokenAndCreateLink();
    }

    private async getDemoTokenAndCreateLink() {
        this.demoToken = await demoViewWebService.getDemoToken();
        this.demoLink = `${this.teacherBaseUrl}/#/demo?token=${this.demoToken.token}`;
    }

    copyDemoLinkToClipboard() {
        navigator.clipboard.writeText(this.demoLink);
        this.$buefy.toast.open({
            message: 'Link wurde in die Zwischenablage kopiert',
            type: 'is-success',
        });
    }
}
