import { EarlyTiger } from '@/models/earlyTiger';
import axios from 'axios';
import HttpException from './HttpException';
import moment from 'moment';

class EarlyTigerWebservice {
    public async getEarlyTigers() {
        try {
            const response = await axios.get('/early_tigers');
            return response.data;
        } catch (error) {
            throw new HttpException(
                error.response.status,
                'Something went wrong. The early_tiger could not be fetched. Please try again later.'
            );
        }
    }

    public async store(early_tiger: EarlyTiger) {
        try {
            const response = await axios.post('/early_tigers/store', {
                module_id: early_tiger.module.id,
                started_at: moment(early_tiger.started_at).format('YYYY-MM-DD'),
                expired_at: moment(early_tiger.expired_at).format('YYYY-MM-DD'),
                price: early_tiger.price,
            });
            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'There was an error storing the early_tiger');
        }
    }

    public async update(early_tiger: EarlyTiger) {
        try {
            const response = await axios.post('/early_tigers/update', {
                id: early_tiger.id,
                started_at: moment(early_tiger.started_at).format('YYYY-MM-DD'),
                expired_at: moment(early_tiger.expired_at).format('YYYY-MM-DD'),
                price: early_tiger.price,
            });
            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'There was an error updating the early_tiger');
        }
    }

    public async remove(early_tiger: EarlyTiger) {
        try {
            const response = await axios.post('/early_tigers/remove', {
                id: early_tiger.id,
            });
            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'There was an error removing the early_tiger');
        }
    }
}

export const earlyTigerWebservice = new EarlyTigerWebservice();
