
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { userWebservice } from '@/webservices/user';
import { moduleWebservice } from '@/webservices/module';
import { Module } from '@/models/module';

import Modal, { ModalOptions } from '@/components/prine/modal/Modal.vue';

import { User } from '@/models/user';

@Component({
    name: 'TransferModuleModal',
    components: {
        Modal,
    },
})
export default class TransferModuleModal extends Vue {
    private emailValid: boolean = false;
    private emailInputTouched: boolean = false;
    private transferToSameUser: boolean = false;
    private email: string = '';
    private user: User | null = null;

    private modalOptions: ModalOptions = {
        width: 500,
        height: null,
        showDelete: false,
        isLoading: false,
        saveButtonText: 'Teilen',
    };

    @Prop() private module: Module;

    @Watch('email')
    private emailChanged(newEmail: string) {
        this.validate();
    }

    private async validate() {
        // Check if the email address is a valid email
        if (this.email === '') {
            this.emailValid = false;
            this.user = null;
            this.emailInputTouched = true;
            return;
        }

        // Check if its a valid email address
        if (!this.validateEmail(this.email)) {
            this.emailValid = false;
            this.user = null;
            return;
        }

        // Check if current module admin and desired transfer admin is the same email
        if (this.module.admin.email === this.email) {
            this.emailInputTouched = true;
            this.transferToSameUser = true;
            return;
        } else {
            this.transferToSameUser = false;
        }

        try {
            // Call the webservice and see if there is an user by this email
            this.user = await userWebservice.getUserByEmail(this.email);
            this.emailInputTouched = true;
        } catch (error) {
            this.emailValid = false;
            this.user = null;
            this.emailInputTouched = true;
        }

        // Check if a correct user object has been returned
        if (this.user && this.user.hasOwnProperty('id')) {
            this.emailValid = true;
            return;
        } else {
            this.emailValid = false;
            this.user = null;
            return;
        }
    }

    private get message() {
        if (!this.emailInputTouched) {
            return '';
        } else {
            return this.transferToSameUser
                ? 'Der User ist bereits Admin des Moduls'
                : this.emailValid
                ? 'Es wurde jemand mit dieser E-Mail gefunden'
                : 'Es wurde keine Lehrperson mit dieser E-Mail Adresse gefunden';
        }
    }

    private get emailType() {
        if (!this.emailInputTouched) {
            return 'is-gray';
        } else {
            return this.emailValid ? 'is-success' : 'is-danger';
        }
    }

    private async transfer() {
        if (this.emailValid) {
            this.modalOptions.isLoading = true;
            if (this.user && this.module && this.module.id) {
                await moduleWebservice.transferModuleOwnership(this.module.id, this.module.admin.id, this.user.id);
                this.modalOptions.isLoading = false;
                this.showSuccessNotification();
                this.toggleModal();
            }
        }
    }

    private validateEmail(email: string) {
        // tslint:disable-next-line:max-line-length
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    /**
     * Show a snackbar notification banner
     */
    private showSuccessNotification() {
        this.$buefy.snackbar.open({
            duration: 5000,
            message:
                'Das Modul wurde erfolgreich zu ' + this.user?.firstname + ' ' + this.user?.lastname + ' transferiert',
            type: 'is-success',
            queue: true,
            position: 'is-top',
        });
    }

    /**
     * Emits the toggle method in the parent
     */
    private toggleModal() {
        this.$emit('reload');
        this.$emit('toggleTransferModuleModal');
    }
}
