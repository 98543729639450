import axios from 'axios';
import HttpException from './HttpException';

class OrderWebservice {
    public async getCrystalPackageOrders() {
        try {
            const response = await axios.get('/orders/getAllCrystalOrders');
            return response.data;
        } catch (error) {
            throw new HttpException(
                error.response.status,
                'Something went wrong. The crystal orders could not be fetched. Please try again later.'
            );
        }
    }

    public async getModuleOrders() {
        try {
            const response = await axios.get('/orders/getAllModuleOrders');
            return response.data;
        } catch (error) {
            throw new HttpException(
                error.response.status,
                'Something went wrong. The module orders could not be fetched. Please try again later.'
            );
        }
    }
}

export const orderWebservice = new OrderWebservice();
