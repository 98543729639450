
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { userWebservice } from '@/webservices/user';
import { User } from '@/models/user';

@Component({
    name: 'UsersList',
})
export default class UsersList extends Vue {
    private users: User[] = [];
    private isLoading: boolean = true;
    private displayStudents: boolean = false;
    private perPage: number = 20;
    private isSearching: boolean = false;
    private searchQuery: string = '';

    @Getter('account/currentUser') private currentUser: User;

    private mounted() {
        console.log('mounted UsersList');
        this.fetchUsers();
    }

    private async fetchUsers() {
        this.users = await userWebservice.fetchUsers();
        this.isLoading = false;
    }

    get filteredUsers() {
        if (this.displayStudents) {
            let filteredUsers = this.users;
            if (this.searchQuery.length > 0) {
                filteredUsers = this.search(filteredUsers);
                this.isSearching = false;
            }
            return filteredUsers;
        } else {
            let filteredUsers = this.users.filter((u) => u.role_id <= 3);
            if (this.searchQuery.length > 0) {
                filteredUsers = this.search(filteredUsers);
                this.isSearching = false;
            }
            return filteredUsers;
        }
    }

    private search(users: User[]) {
        this.isSearching = true;
        const regExQuery = new RegExp(this.searchQuery.trim(), 'i');
        let usersCopy = new Array<User>();
        usersCopy = JSON.parse(JSON.stringify(users));
        return usersCopy.filter((user: User) => {
            const userMatch =
                user.firstname.search(regExQuery) !== -1 ||
                user.lastname.search(regExQuery) !== -1 ||
                user.email.search(regExQuery) !== -1;
            return userMatch;
        });
    }

    private clearSearch() {
        // Reset the search query
        this.searchQuery = '';
    }

    private async updateUser(user: User) {
        this.isLoading = true;
        console.log(user);
        await userWebservice.updateUser(user);
        this.fetchUsers();
        this.isLoading = false;

        this.$buefy.snackbar.open({
            duration: 3000,
            message: 'Updated ' + user.email,
            type: 'is-success',
            position: 'is-bottom-right',
            queue: false,
        });
    }

    private updateCrystalsPrompt(user: User) {
        this.$buefy.dialog.prompt({
            title: user.email,
            message: this.$i18n.t('phrases.addDiamondsMessage').toString(),
            inputAttrs: {
                type: 'number',
                placeholder: '10',
                value: '10',
                maxlength: 3,
            },
            confirmText: this.$i18n.t('labels.confirmation').toString(),
            trapFocus: true,
            onConfirm: async (value: string) => {
                await this.updateDiamonds(user, Number(value));
                this.$emit('reloadModules');
            },
        });
    }

    private async updateDiamonds(user: User, diamonds: number) {
        await userWebservice.updateDiamonds(user, diamonds);
        this.fetchUsers();
    }
}
