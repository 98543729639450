import axios from 'axios';
import HttpException from './HttpException';
import { Schooltype } from '@/models/schooltype';

class SchooltypeWebservice {
    public async getAll() {
        try {
            const response = await axios.get<Schooltype[]>('/schooltypes');
            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'There was an error fetching the schooltypes');
        }
    }

    public async store(schooltype: Schooltype) {
        try {
            const response = await axios.post('/schooltypes/store', {
                name: schooltype.name,
                title: schooltype.title,
            });
            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'There was an error storing the schooltypes');
        }
    }

    public async update(schooltype: Schooltype) {
        try {
            const response = await axios.post('/schooltypes/update', {
                id: schooltype.id,
                name: schooltype.name,
                title: schooltype.title,
            });
            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'There was an error updating the schooltypes');
        }
    }

    public async remove(schooltype: Schooltype) {
        try {
            const response = await axios.post('/schooltypes/remove', {
                id: schooltype.id,
            });
            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'There was an error removing the schooltypes');
        }
    }
}
export const schooltypeWebservice = new SchooltypeWebservice();
