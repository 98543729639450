
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { User } from '@/models/user';

@Component({
    name: 'AppSwitcher',
})
export default class AppSwitcher extends Vue {
    @Getter('account/loggedIn') private loggedIn: boolean;
    @Getter('account/currentUser') private currentUser: User;

    get storeAppUrl() {
        return process.env.VUE_APP_STORE_URL;
    }

    get studentAppUrl() {
        return process.env.VUE_APP_STUDENT_URL;
    }

    get teacherAppUrl() {
        return process.env.VUE_APP_TEACHER_URL;
    }

    get backofficeAppUrl() {
        return process.env.VUE_APP_BACKOFFICE_URL;
    }

    private redirectTo(url: string, newTab: boolean = false) {
        if (newTab) {
            window.open(url, '_blank');
        } else {
            window.location.href = url;
        }
    }
}
