
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { moduleWebservice } from '@/webservices/module';
import { Module } from '@/models/module';
import { ModuleStatusValues } from '@/models/moduleStatus';

import { User } from '@/models/user';

@Component({
    name: 'ManagePublishedModules',
})
export default class ManagePublishedModules extends Vue {
    public moduleStatus = ModuleStatusValues;
    private modules: Module[] = [];
    private isLoading: boolean = true;
    private isLoaded: boolean = false;
    private perPage: number = 20;
    private isSearching: boolean = false;
    private searchQuery: string = '';

    @Getter('account/currentUser') private currentUser: User;

    private mounted() {
        this.fetchModules();
    }

    private async fetchModules() {
        this.modules = await moduleWebservice.fetchPublishedModules();
        this.isLoading = false;
        this.isLoaded = true;
    }

    private imagePath(cover_path: string) {
        return process.env.VUE_APP_BACKEND_BASE_URL + cover_path;
    }

    get storeAppUrl() {
        return process.env.VUE_APP_STORE_URL;
    }

    private async viewInStore(module_id: number) {
        const url = this.storeAppUrl + '/modules/' + module_id;
        console.log(url);
        window.open(url, '_blank');
    }

    get filteredModules() {
        let filteredModules = JSON.parse(JSON.stringify(this.modules));
        if (this.searchQuery.length > 0) {
            filteredModules = this.search(filteredModules);
            this.isSearching = false;
        }
        return filteredModules;
    }

    private search(modules: Module[]) {
        this.isSearching = true;
        const regExQuery = new RegExp(this.searchQuery.trim(), 'i');
        let modulesCopy = new Array<Module>();
        modulesCopy = JSON.parse(JSON.stringify(modules));
        return modulesCopy.filter((module: Module) => {
            const moduleMatch =
                module.name.search(regExQuery) !== -1 ||
                module.admin.firstname.search(regExQuery) !== -1 ||
                module.admin.lastname.search(regExQuery) !== -1;
            return moduleMatch;
        });
    }

    private clearSearch() {
        // Reset the search query
        this.searchQuery = '';
    }

    private async updateModule(event: any, module: Module) {
        if (event.target.value !== '' && module.id) {
            this.isLoading = true;
            const status_name = event.target.value;
            module = await moduleWebservice.updateModule(module.id, status_name);
            await this.fetchModules();
            this.$buefy.snackbar.open({
                duration: 5000,
                message: module.name + ' Status wurde geändert in: ' + module.status.title,
                type: 'is-success',
                position: 'is-bottom-right',
                queue: false,
            });
            this.isLoading = false;
        }
    }
}
