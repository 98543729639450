
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ModuleOrder } from '@/models/moduleOrder.ts';
import { orderWebservice } from '@/webservices/order';
import moment from 'moment';
import { Module } from 'vuex';

@Component({
    name: 'ManageModuleOrders',
    components: {},
})
export default class ManageModuleOrders extends Vue {
    private isLoading: boolean = true;
    private moduleOrders: ModuleOrder[] = [];

    private mounted() {
        this.getModuleOrders();
    }

    private async getModuleOrders() {
        this.isLoading = true;

        const response = await orderWebservice.getModuleOrders();

        if (!response) {
            this.$buefy.toast.open({
                message: 'Http 500: Es ist ein Fehler aufgetreten. Versuche es bitte später wieder.',
                type: 'is-danger',
                duration: 3000,
            });
        } else {
            this.moduleOrders = response;
            this.sortModuleOrders();
        }
        this.isLoading = false;
    }

    private sortModuleOrders() {
        this.moduleOrders.sort(function(a: ModuleOrder, b: ModuleOrder) {
            return new Date(b.ordered_date).getTime() - new Date(a.ordered_date).getTime();
        });
    }
}
