
import { Component, Prop, Vue } from 'vue-property-decorator';
import { voucherWebservice } from '@/webservices/voucher';
import { ToastProgrammatic as Toast } from 'buefy';
import { HtmlHelper } from '@/helpers/HtmlHelper';
import { CrystalVoucher } from '@/models/crystalVoucher';
import { CrystalPackage } from '@/models/crystalPackage';

@Component({
    name: 'GenerateModal',
    components: {},
})
export default class GenerateModal extends Vue {
    private isGeneratorModalActive: boolean = true;
    private number: number = 0;
    private htmlHelper: HtmlHelper = new HtmlHelper();
    private isGenerating: boolean = false;
    private packages: CrystalPackage[] = [];
    private selectedCrystalPackage: CrystalPackage = {} as CrystalPackage;
    private description: string = '';

    // Mounted is called initially
    private async mounted() {
        this.htmlHelper.setFocusById('voucher-input');

        // Fetch all crystal packages
        this.packages = await voucherWebservice.getAllPackages();

        // Set default value after its loaded
        if (this.packages.length > 0) {
            this.selectedCrystalPackage = this.packages[0];
        }
    }

    private generate() {
        this.isGenerating = true;
        console.log('Number ', this.number);
        console.log('Selected package ', this.selectedCrystalPackage.id);
        console.log('Description', this.description);

        const response = voucherWebservice
            .generate(this.number, this.selectedCrystalPackage.id, this.description)
            .then((res) => {
                Toast.open({ message: this.number + ' Vouchers wurde generiert', type: 'is-success' });
                this.isGeneratorModalActive = false;
                this.isGenerating = true;
                this.toggleModal();
                this.$emit('reloadAndCloseGeneratorModal');
            })
            .catch((err: Error) => {
                Toast.open({ message: 'Vouchers konnten nicht generiert werden', type: 'is-danger' });
            });
    }

    private toggleModal() {
        this.$emit('closeGeneratorModal');
    }
}
