
import { Component, Prop, Vue } from 'vue-property-decorator';
import GeneratorHeader from '@/components/manage/vouchers/GeneratorHeader.vue';
import VoucherDataTable from '@/components/manage/vouchers/VoucherDataTable.vue';
import { CrystalVoucher } from '@/models/crystalVoucher';
import { voucherWebservice } from '@/webservices/voucher';

@Component({
    name: 'ManageVouchers',
    components: {
        GeneratorHeader,
        VoucherDataTable,
    },
})
export default class ManageVouchers extends Vue {
    private crystalVouchers: CrystalVoucher[] = [];
    private isLoading: boolean = true;

    private mounted() {
        this.loadVouchers();
    }

    private async loadVouchers() {
        this.isLoading = true;
        this.crystalVouchers = await voucherWebservice.getAll();
        this.isLoading = false;
    }
}
