
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { User } from '@/models/user';

@Component({
    name: 'Navbar',
})
export default class Navbar extends Vue {
    private isActive: boolean = false;

    @Getter('account/loggedIn') private loggedIn: boolean;
    @Getter('account/haveAccess') private haveAccess: boolean;
    @Getter('account/isEmailConfirmed') private isEmailConfirmed: boolean;
    @Getter('account/currentUser') private currentUser: User;

    private hideMenu() {
        setTimeout(() => {
            this.isActive = false;
        }, 100);
    }

    private goToHomePage() {
        if (this.loggedIn) {
            this.$router.push('/');
        } else {
            this.$router.push('/auth/login');
        }
    }

    private toggleNav() {
        this.isActive = !this.isActive;
    }
}
