
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { EarlyTiger } from '@/models/earlyTiger';
import { earlyTigerWebservice } from '@/webservices/earlyTiger';
import { moduleWebservice } from '@/webservices/module';
import { Module } from '@/models/module';
import moment from 'moment-timezone';

moment.tz.setDefault('Europe/Zurich');

enum Mode {
    EDIT,
    CREATE,
}

@Component({
    name: 'EarlyTigerStore',
})
export default class EarlyTigerStore extends Vue {
    private newEarlyTiger: EarlyTiger = {
        module: {} as Module,
        expired_at: new Date(),
        started_at: new Date(),
    } as EarlyTiger;

    private options = {
        locale: 'de-DE',
    };
    private modules: Module[] = [];
    private isLoading: boolean = false;
    private mode: Mode = Mode.CREATE;

    @Prop() private early_tiger: EarlyTiger;

    private async mounted() {
        this.modules = await moduleWebservice.fetchAwaitingPublicationModules();

        // filter out modules that have early_tiger
        this.modules = this.modules.filter((module: Module) => {
            return module.early_tiger === null;
        });

        if (this.early_tiger) {
            this.newEarlyTiger = JSON.parse(JSON.stringify(this.early_tiger));
            this.newEarlyTiger.started_at = moment(this.newEarlyTiger.started_at).toDate();
            this.newEarlyTiger.expired_at = moment(this.newEarlyTiger.expired_at).toDate();
            this.mode = Mode.EDIT;
        }
    }

    private async store() {
        this.isLoading = true;
        if (this.mode === Mode.CREATE) {
            await earlyTigerWebservice.store(this.newEarlyTiger);
        } else if (this.mode === Mode.EDIT) {
            await earlyTigerWebservice.update(this.newEarlyTiger);
        }

        this.isLoading = false;

        // Trigger reload and close the modal
        this.$emit('reload');
        this.toggleIsCreateModalActive();
    }

    private toggleIsCreateModalActive() {
        this.$emit('closeModal');
    }

    private get isCreateMode() {
        return this.mode === Mode.CREATE;
    }

    private startedInputChanged(value: any) {
        console.log('Started input: ', value);
    }

    private expiredInputChanged(value: any) {
        console.log('Expired input: ', value);
    }
}
