
import { Component, Prop, Vue } from 'vue-property-decorator';
import router from 'vue-router';
import { Getter } from 'vuex-class';
import { User } from './models/user';
import { userWebservice } from '@/webservices/user';
import OfflineChecker from '@/components/prine/offline/OfflineChecker.vue';
import Navbar from '@/components/layout/Navbar.vue';
import Sidebar from '@/components/layout/Sidebar.vue';
import Footer from '@/components/layout/Footer.vue';

@Component({
    name: 'App',
    components: {
        OfflineChecker,
        'pi-navbar': Navbar,
        'pi-sidebar': Sidebar,
        'pi-footer': Footer,
    },
})
export default class App extends Vue {
    protected version: string = '';
    private isSidebarReduced: boolean = false;

    @Getter('account/loggedIn') private loggedIn: boolean;
    @Getter('account/haveAccess') private haveAccess: boolean;

    // Mounted is called initially
    private mounted() {
        if (this.loggedIn) {
            // reload the user info
            userWebservice
                .getUserInfo()
                .then((getUserInfoResponse) => {
                    this.$store.commit('account/setCurrentUser', getUserInfoResponse.data);
                    this.$store.commit('account/setHaveAccess', true);
                    this.$router.push({ path: '/' });
                })
                .catch((error: Error) => {
                    this.$store.commit('account/setHaveAccess', false);
                    this.$router.push({ path: '/auth/login' });
                });
        }
    }

    private toggleSidebar() {
        this.isSidebarReduced = !this.isSidebarReduced;
    }
}
