
import { Component, Vue, Prop } from 'vue-property-decorator';
import TransferModuleModal from '@/components/manage/modules/all/TransferModuleModal.vue';
import { Module } from '@/models/module';

@Component({
    name: 'ModulesList',
    components: {
        TransferModuleModal,
    },
})
export default class TransferModule extends Vue {
    private isTransferModalActive: boolean = false;
    @Prop() private module: Module;

    private transferModule() {
        this.isTransferModalActive = true;
    }

    private toggleTransferModuleModal() {
        this.isTransferModalActive = !this.isTransferModalActive;
    }

    private reload() {
        this.$emit('reload');
    }
}
