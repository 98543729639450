
import { Component, Prop, Vue } from 'vue-property-decorator';
import GeneratorModal from '@/components/manage/vouchers/GeneratorModal.vue';
import ExportButton from '@/components/manage/vouchers/ExportButton.vue';
import { CrystalVoucher } from '@/models/crystalVoucher';

@Component({
    name: 'GeneratorHeader',
    components: {
        GeneratorModal,
        ExportButton,
    },
})
export default class GeneratorHeader extends Vue {
    private isGeneratorModalActive: boolean = false;
    @Prop() private crystalVouchers: CrystalVoucher[];

    private reloadAndCloseGeneratorModal() {
        this.closeGeneratorModal();
        this.$emit('reload');
    }

    private openGeneratorModal() {
        this.isGeneratorModalActive = true;
    }

    private closeGeneratorModal() {
        this.isGeneratorModalActive = false;
    }
}
