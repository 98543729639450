
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

export interface ModalOptions {
    width: number | null;
    height: number | null;
    showDelete: boolean;
    isLoading: boolean;
    saveButtonText?: string | null;
}

@Component({
    name: 'Modal',
})
export default class Modal extends Vue {

    @Prop() private title: string;

    @Prop({
            type: Object,
            default: function () { return { width: 800, height: null, showDelete: false, isLoading: false, saveButtonText: 'Speichern'} }
        }) private options: ModalOptions;

    private toggleModal() {
        this.$emit('toggleModal');
    }

    private store() {
        console.log('store method fired from prine modal');
        this.$emit('store');
    }

    private remove() {
        this.$emit('remove');
    }

    private getStyle(){
        if (this.options) {
            return  this.getWidth() + this.getHeight();
        }
    }

    private getWidth(){
        return this.options.width !== null ? 'width:' + this.options.width +'px;' : '';
    }

    private getHeight(){
        return this.options.height !== null ? 'height:' + this.options.height +'px;' : '';
    }
}
