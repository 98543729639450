
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { earlyTigerWebservice } from '@/webservices/earlyTiger';
import EarlyTigerList from './EarlyTigerList.vue';
import EarlyTigerStoreButton from './EarlyTigerStoreButton.vue';

@Component({
    name: 'EarlyTigers',
    components: {
        EarlyTigerList,
        EarlyTigerStoreButton,
    },
})
export default class EarlyTiger extends Vue {
    private early_tigers: EarlyTiger[] = [];

    private mounted() {
        this.loadEarlyTigers();
    }

    private async loadEarlyTigers() {
        this.early_tigers = await earlyTigerWebservice.getEarlyTigers();
    }
}
