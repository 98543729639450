import Vue from 'vue';
import router from 'vue-router';
import Dashboard from '../components/dashboard/Dashboard.vue';

import AccountSettings from '../components/profile/AccountSettings.vue';
import Login from '../components/auth/Login.vue';
import Logout from '../components/auth/Logout.vue';
import Register from '../components/auth/Register.vue';
import ConfirmEmail from '../components/auth/ConfirmEmail.vue';
import ConfirmInfo from '../components/auth/ConfirmInfo.vue';
import ForgotPassword from '../components/auth/ForgotPassword.vue';
import ResetPassword from '../components/auth/ResetPassword.vue';
import ManageCrystalOrders from '../components/manage/orders/ManageCrystalOrders.vue';
import ManageModuleOrders from '../components/manage/orders/ManageModuleOrders.vue';
import ManageVouchers from '../components/manage/vouchers/ManageVouchers.vue';

import PiquestSubjectsList from '../components/manage/piquestSubjects/PiquestSubjectsList.vue';
import SchoollevelsList from '../components/manage/schoollevels/SchoollevelsList.vue';
import SchooltypesList from '../components/manage/schooltypes/SchooltypesList.vue';
import TagsList from '../components/manage/tags/TagsList.vue';

import UsersList from '../components/manage/UsersList.vue';
import ModulesList from '../components/manage/modules/all/ModulesList.vue';
import ManagePublishedModules from '../components/manage/modules/ManagePublishedModules.vue';
import Announcements from '../components/manage/modules/announcements/Announcements.vue';
import EarlyTigers from '../components/manage/modules/earlyTigers/EarlyTigers.vue';
import DemoView from '../components/manage/demoView/DemoView.vue';

Vue.use(router);

export default new router({
    routes: [
        // Root
        // {
        //     path: '/',
        //     name: 'Home',
        //     component: Home,
        // },

        // Profile
        {
            path: '/account',
            name: 'AccountSettings',
            component: AccountSettings,
        },

        // Demo view
        {
            path: '/demo-view',
            name: 'DemoView',
            component: DemoView,
        },

        // Authentication
        {
            path: '/auth/login',
            name: 'Login',
            component: Login,
        },
        {
            path: '/auth/logout',
            name: 'Logout',
            component: Logout,
        },
        {
            path: '/auth/register',
            name: 'Register',
            component: Register,
        },
        {
            path: '/auth/confirmEmail',
            name: 'ConfirmEmail',
            component: ConfirmEmail,
        },
        {
            path: '/auth/confirmInfo',
            name: 'ConfirmInfo',
            component: ConfirmInfo,
        },
        {
            path: '/auth/forgotPassword',
            name: 'ForgotPassword',
            component: ForgotPassword,
        },
        {
            path: '/auth/resetPassword',
            name: 'ResetPassword',
            component: ResetPassword,
        },

        // manage
        {
            path: '/',
            name: 'Dashboard',
            component: Dashboard,
        },

        {
            path: '/users',
            name: 'UsersList',
            component: UsersList,
        },
        {
            path: '/orders/crystals',
            name: 'ManageOrders',
            component: ManageCrystalOrders,
        },
        {
            path: '/orders/module',
            name: 'ManageModuleOrders',
            component: ManageModuleOrders,
        },
        {
            path: '/orders/crystal',
            name: 'ManageCrystalOrders',
            component: ManageCrystalOrders,
        },
        {
            path: '/vouchers',
            name: 'ManageVouchers',
            component: ManageVouchers,
        },
        {
            path: '/modules/all',
            name: 'ModulesList',
            component: ModulesList,
        },
        {
            path: '/modules/published',
            name: 'ManagePublishedModules',
            component: ManagePublishedModules,
        },
        // {
        //     path: '/modules/announcements',
        //     name: 'Announcements',
        //     component: Announcements,
        // },
        {
            path: '/modules/early_tigers',
            name: 'EarlyTigers',
            component: EarlyTigers,
        },
        {
            path: '/piquest-subjects',
            name: 'PiquestSubjectsList',
            component: PiquestSubjectsList,
        },

        {
            path: '/schoollevels',
            name: 'SchoollevelsList',
            component: SchoollevelsList,
        },
        {
            path: '/schooltypes',
            name: 'SchooltypesList',
            component: SchooltypesList,
        },
        {
            path: '/tags',
            name: 'TagsList',
            component: TagsList,
        },
    ],
});
