
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { CrystalVoucher } from '@/models/crystalVoucher';
import moment from 'moment';

@Component({
    name: 'VoucherDataTable',
    components: {},
})
export default class VoucherDataTable extends Vue {
    private displayCodes: boolean = false;
    private displayEmails: boolean = false;
    private perPage: number = 20;

    @Prop() private crystalVouchers: CrystalVoucher[];
    @Prop() private isLoading: boolean;

    private reload() {
        this.$emit('reload', this.displayCodes);
    }
}
