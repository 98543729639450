
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import EarlyTigerCreate from './EarlyTigerCreate.vue';
import { earlyTigerWebservice } from '@/webservices/earlyTiger';
import { EarlyTiger } from '@/models/earlyTiger';
import EarlyTigerStoreButton from './EarlyTigerStoreButton.vue';

@Component({
    name: 'EarlyTigerList',
    components: {
        EarlyTigerStoreButton,
    },
})
export default class EarlyTigerList extends Vue {
    @Prop() private early_tigers: EarlyTiger[];
    private perPage = 10;

    private toggleEditModal(early_tiger: EarlyTiger) {
        console.log('toggle edit modal');
    }

    private toggleDeleteModal(early_tiger: EarlyTiger) {
        this.$buefy.dialog.confirm({
            title: 'Ankündigung löschen',
            message: 'Bist du sicher, dass du die Ankündigung löschen willst?',
            confirmText: 'Ja, löschen',
            type: 'is-danger',
            hasIcon: true,
            onConfirm: async () => {
                await earlyTigerWebservice.remove(early_tiger);
                this.$emit('reload');
            },
        });
    }

    private reload() {
        this.$emit('reload');
    }
}
