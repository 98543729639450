import axios from 'axios';
import HttpException from './HttpException';
import { Tag } from '@/models/tag';

class TagWebservice {
    public async getAll() {
        try {
            const response = await axios.get<Tag[]>('/tags');
            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'There was an error fetching the tags');
        }
    }

    public async store(tag: Tag) {
        try {
            const response = await axios.post('/tags/store', {
                name: tag.name,
                title: tag.title,
                color: tag.color,
            });
            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'There was an error storing the tags');
        }
    }

    public async update(tag: Tag) {
        try {
            const response = await axios.post('/tags/update', {
                id: tag.id,
                name: tag.name,
                title: tag.title,
                color: tag.color,
            });
            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'There was an error updating the tags');
        }
    }

    public async remove(tag: Tag) {
        try {
            const response = await axios.post('/tags/remove', {
                id: tag.id,
            });
            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'There was an error removing the tags');
        }
    }
}
export const tagWebservice = new TagWebservice();
