import axios from 'axios';
import HttpException from './HttpException';
import { Schoollevel } from '@/models/schoollevel';

class SchoollevelWebservice {
    public async getAll() {
        try {
            const response = await axios.get<Schoollevel[]>('/schoollevels');
            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'There was an error fetching the schoollevels');
        }
    }

    public async store(schoollevel: Schoollevel) {
        try {
            const response = await axios.post('/schoollevels/store', {
                name: schoollevel.name,
                title: schoollevel.title,
                description: schoollevel.description,
                start_age: schoollevel.start_age,
                end_age: schoollevel.end_age,
            });
            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'There was an error storing the schoollevels');
        }
    }

    public async update(schoollevel: Schoollevel) {
        try {
            const response = await axios.post('/schoollevels/update', {
                id: schoollevel.id,
                name: schoollevel.name,
                title: schoollevel.title,
                description: schoollevel.description,
                start_age: schoollevel.start_age,
                end_age: schoollevel.end_age,
            });
            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'There was an error updating the schoollevels');
        }
    }

    public async remove(schoollevel: Schoollevel) {
        try {
            const response = await axios.post('/schoollevels/remove', {
                id: schoollevel.id,
            });
            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'There was an error removing the schoollevels');
        }
    }
}
export const schoollevelWebservice = new SchoollevelWebservice();
