
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import EarlyTigerStore from './EarlyTigerStore.vue';
import { EarlyTiger } from '@/models/earlyTiger';

@Component({
    name: 'EarlyTigerStoreButton',
    components: {
        EarlyTigerStore,
    },
})
export default class EarlyTigerStoreButton extends Vue {
    private showModal: boolean = false;
    @Prop() private early_tiger: EarlyTiger;

    private reload() {
        this.$emit('reload');
    }
}
