import Vue from 'vue';
import Vuex from 'vuex';

// Import different modules
import account from './modules/account/store';

import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

export default new Vuex.Store({
    plugins: [createPersistedState()],

    modules: {
        // All modules
        account,
    },
});
