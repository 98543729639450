import { render, staticRenderFns } from "./ManagePublishedModules.vue?vue&type=template&id=783ce447&scoped=true&"
import script from "./ManagePublishedModules.vue?vue&type=script&lang=ts&"
export * from "./ManagePublishedModules.vue?vue&type=script&lang=ts&"
import style0 from "./ManagePublishedModules.vue?vue&type=style&index=0&id=783ce447&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "783ce447",
  null
  
)

export default component.exports