
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { CrystalVoucher } from '@/models/crystalVoucher.ts';
import moment from 'moment';
import { voucherWebservice } from '@/webservices/voucher';

@Component({
    name: 'ExportButton',
    components: {},
})
export default class ExportButton extends Vue {
    @Prop() private crystalVouchers: CrystalVoucher[];

    private async exportToXlsx() {
        console.log(this.crystalVouchers);

        const crystalVouchersArray: string[][] = [];

        crystalVouchersArray.push([
            'Nr.',
            'Codes',
            'Wert',
            'Status',
            'Eingelöst am',
            'Eingelöst von',
            'Erstellt am',
            'Bemerkungen',
        ]);

        this.crystalVouchers.forEach((crystalVoucher) => {
            const crystalVoucherArray: string[] = [
                crystalVoucher.id.toString(),
                crystalVoucher.code,
                crystalVoucher.package.crystals.toString(),
                crystalVoucher.redeemed.toString(),
                crystalVoucher.redeem_date?.toString().slice(0, 11) ?? '',
                crystalVoucher.redeemed
                    ? crystalVoucher.user?.firstname + ' ' + crystalVoucher.user?.lastname ?? ''
                    : '',
                crystalVoucher.created_at.toString().slice(0, 11),
                crystalVoucher.description !== null ? crystalVoucher.description : '',
            ];
            crystalVouchersArray.push(crystalVoucherArray);
        });

        console.log(crystalVouchersArray);
        const serverFileName = await this.toExcel(crystalVouchersArray);

        const url = process.env.VUE_APP_API_URL + 'vouchers/getExcelFile?fileName=' + serverFileName;
        const encodedUri = encodeURI(url);
        const link = document.createElement('a');
        const filename = moment().format('YYYY-MM-DD_HH-mm-ss') + '.xlsx';

        link.setAttribute('href', encodedUri);
        link.setAttribute('download', filename);

        document.body.appendChild(link); // Required for FF
        link.click();
    }

    private async toExcel(data: string[][]) {
        try {
            const fileName = await voucherWebservice.toExcel(data);
            return fileName;
        } catch (error) {
            console.error(error);
            throw error;
        }
        console.log(data);
    }
}
