
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { statisticsWebservice } from '@/webservices/statistics';
import { StatisticsCategory } from '@/models/statisticsCategory';
import { User } from '@/models/user';
import StatisticCard from '@/components/dashboard/StatisticCard.vue';

@Component({
    name: 'Dashboard',
    components: {
        StatisticCard,
    },
})
export default class Dashboard extends Vue {
    @Getter('account/loggedIn') private loggedIn: boolean;
    @Getter('account/currentUser') private currentUser: User;
    @Getter('account/isEmailConfirmed') private isEmailConfirmed: boolean;

    private isLoading: boolean = true;
    private statisticsCategories: StatisticsCategory[] = [];

    private mounted() {
        console.log('mounted Dashboard');
        if (!this.loggedIn) {
            this.$router.push('auth/login');
        } else {
            this.fetchStatistics();
        }
    }

    private async fetchStatistics() {
        const res = await statisticsWebservice.getAll();

        if (!res) {
            this.$buefy.toast.open({
                message: '500: Uh oh, something went wrong. Please try again later',
                type: 'is-danger',
                duration: 5000,
            });
        }
        this.statisticsCategories = res;
        this.isLoading = false;
    }
}
