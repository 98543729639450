import axios from 'axios';
import { Schooltype } from '@/models/schooltype';
import HttpException from '@/webservices/HttpException';
import { DemoToken } from '@/models/demoToken';

class DemoViewWebService {
    public async getDemoToken() {
        try {
            const response = await axios.get<DemoToken>('/demoView/getDemoToken');
            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'There was an error fetching the demo token');
        }
    }
}
export const demoViewWebService = new DemoViewWebService();
