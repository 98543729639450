
import axios from 'axios';
import router from 'vue-router';
import { User } from '@/models/user';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { userWebservice } from '@/webservices/user';
@Component({
    name: 'Login',
})
export default class Login extends Vue {
    // Data
    private user: User = {
        id: 0,
        role_id: 4,
        firstname: '',
        lastname: '',
        email: '',
        new_email: '',
        email_confirmation: '',
        password: '',
        password_confirmation: '',
        new_password: '',
        email_confirmed: false,
        activated: false,
    };
    private disabled: boolean = false;
    private errorMsg: string = '';
    private isLoading: boolean = false;

    // Methods
    private login() {
        if (this.user.email === '' || this.user.password === '') {
            this.errorMsg = 'Die Emailadresse oder das Passwort fehlt.';
            return 0;
        }

        this.isLoading = true;

        userWebservice
            .login(this.user.email, this.user.password)
            .then((response) => {
                this.errorMsg = '';
                this.$store.commit('account/setApiToken', response.data.api_token);

                userWebservice.getUserInfo().then((getUserInfoResponse) => {
                    this.$store.commit('account/setCurrentUser', getUserInfoResponse.data);
                    this.$router.push({ path: '/' });
                    this.isLoading = false;
                    this.$store.commit('account/setHaveAccess', true);
                });
            })
            .catch((error) => {
                this.errorMsg = 'Das Passwort oder der Benutzername ist nicht korrekt';
                console.log(error);
                this.isLoading = false;
            });
    }
}
