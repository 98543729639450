
import router from 'vue-router';
import { User } from '@/models/user';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { userWebservice } from '@/webservices/user';
@Component({
    name: 'ConfirmInfo',
})
export default class ConfirmInfo extends Vue {
    // Data
    private disabled: boolean = false;
    private storing: boolean = false;
    private confirmed: boolean = false;

    private errorMsg: string = '';
    private serverErrorMsg: string = '';

    private user: User = {} as User;

    @Getter('account/loggedIn') private loggedIn: boolean;
    @Getter('account/isEmailConfirmed') private isEmailConfirmed: boolean;
    @Getter('account/currentUser') private currentUser: User;

    // Mounted is called initially
    private mounted() {
        this.user = JSON.parse(JSON.stringify(this.currentUser));
    }

    // Methods
    private confirm() {
        console.log('confirm');

        if (this.validate()) {
            console.log('validated');
            this.storing = true;
            userWebservice
                .confirmInfo(this.user)
                .then((response) => {
                    this.errorMsg = '';
                    this.confirmed = true;
                    this.storing = false;
                    setTimeout(() => {
                        userWebservice.getUserInfo().then((userWebserviceResponse) => {
                            this.$store.commit('account/setCurrentUser', userWebserviceResponse.data);
                            this.$router.push({ path: '/' });
                        });
                    }, 2500);
                })
                .catch((error) => {
                    this.serverErrorMsg = 'error';
                    this.storing = false;
                    console.log(error);
                });
        }
    }

    private validateEmail(email: string) {
        /* tslint:disable-next-line:max-line-length */
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email.toLowerCase());
    }

    private validate() {
        if (this.user.firstname === '') {
            this.errorMsg = 'firstname';
            return 0;
        } else if (this.user.lastname === '') {
            this.errorMsg = 'lastname';
            return 0;
        } else if (!this.validateEmail(this.user.email)) {
            this.errorMsg = 'email';
            return 0;
        } else {
            return true;
        }
    }
}
