import axios from 'axios';
import { Module } from '../models/module';
import HttpException from './HttpException';

class ModuleWebservice {
    public async fetchAllModules() {
        try {
            const response = await axios.get<Module[]>('/modules');
            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'There was an error fetching all modules');
        }
    }

    public async loadModuleDetails(module_id: number) {
        try {
            const response = await axios.get<Module>('/modules/loadModuleWithAllDetails', {
                params: {
                    module_id,
                },
            });
            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'There was an error fetching all modules');
        }
    }

    public async fetchPublishedModules() {
        try {
            const response = await axios.get<Module[]>('/modules/published');
            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'There was an error fetching published modules');
        }
    }

    public async fetchAwaitingPublicationModules() {
        try {
            const response = await axios.get<Module[]>('/modules/awaitingpublication');
            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'There was an error fetching published modules');
        }
    }

    public async updateModule(module_id: number, status_name: string) {
        try {
            const response = await axios.post<Module>('/modules/updateStatus', {
                module_id,
                status_name,
            });
            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'There was an error while updating the module');
        }
    }

    public async transferModuleOwnership(module_id: number, from_user_id: number, to_user_id: number) {
        try {
            const response = await axios.post<Module>('/modules/transferModuleOwnership', {
                module_id,
                from_user_id,
                to_user_id,
            });
            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'There was an error while transfering the module ownership');
        }
    }
}

export const moduleWebservice = new ModuleWebservice();
