import axios from 'axios';
import HttpException from './HttpException';
import { CrystalVoucher } from '@/models/crystalVoucher';
import { CrystalPackage } from '@/models/crystalPackage';

class VoucherWebservice {
    public async getAll() {
        try {
            const response = await axios.get<CrystalVoucher[]>('/vouchers');
            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'There was an error loading the Crystal Vouchers');
        }
    }

    public async getAllPackages() {
        try {
            const response = await axios.get<CrystalPackage[]>('/vouchers/packages');
            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'There was an error loading the Crystal Vouchers');
        }
    }

    public async generate(n: number, package_id: number, description: string) {
        try {
            const response = await axios.post('/vouchers/generate', {
                number: n,
                crystal_package_id: package_id,
                description,
            });
            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'There was an error generating the vouchers');
        }
    }

    public async toExcel(data: string[][]) {
        try {
            const response = await axios.post('/vouchers/toExcel', {
                data,
            });
            return response.data;
        } catch (error) {
            console.error(error);
            return '';
        }
    }
}
export const voucherWebservice = new VoucherWebservice();
