import axios from 'axios';
import HttpException from './HttpException';

class StatisticsWebservice {
    public async getAll() {
        try {
            const response = await axios.get('/stats');
            return response.data;
        } catch (error) {
            throw new HttpException(error.response.status, 'There was an error fetching the stats');
        }
    }
}
export const statisticsWebservice = new StatisticsWebservice();
