
import { Component, Prop, Vue } from 'vue-property-decorator';
import router from 'vue-router';
import { Statistic } from '@/models/statistic';
import moment from 'moment';

@Component({
    name: 'StatisticCard',
    components: {},
})
export default class StatisticCard extends Vue {
    @Prop() private categoryTitle: string;
    @Prop() private color: string;
    @Prop() private statistic: Statistic;

    private thisMonth: string = '';
    private lastMonth: string = '';
    private previousMonth: string = '';
    private saleValue: number = 0;
    private arrow: string = 'fas fa-arrow-up';

    private mounted() {
        moment.locale('de');
        this.thisMonth = moment()
            .startOf('month')
            .format('MMMM YYYY');
        this.previousMonth = moment()
            .startOf('month')
            .subtract(1, 'months')
            .format('MMMM');
    }

    private tooltip(value: number) {
        return 'Vergleich zum ' + this.previousMonth + ' : ' + value;
    }

    private trend(lastMonth: number, previousMonth: number) {
        return lastMonth > previousMonth
            ? 'fas fa-arrow-up'
            : lastMonth < previousMonth
            ? 'fas fa-arrow-down'
            : 'fas fa-arrow-right';
    }

    private trendColor(lastMonth: number, previousMonth: number) {
        return lastMonth > previousMonth ? 'green' : lastMonth < previousMonth ? 'red' : 'black';
    }
}
